<template>
  <div :id="viewCSSId" :class="viewCSSClasses" ref="scene">
    <div class="section-foreground container">
      <div class="layout-row row-1 cols layout-align-center-start">
        <div class="col-1 layout-column layout-align-start-end col-padding">
          <section-title
            :partNo="part.id"
            :partTitle="part.title"
            data-depth="2.7"
            class="text-right parallax-item parallax-relative"
            :show="showTitle"
          ></section-title>

          <lead
            id="lead-left"
            :show="showLead"
            class="parallax-item parallax-relative"
            data-depth="1.8"
            :textAlign="'right'"
            :timescale="1"
            >{{ data.meta.description }}</lead
          >
        </div>
        <div class="col-2 col-padding layout-align-center-center layout-column">
          <lead
            id="lead-right"
            :show="showQuote"
            class="parallax-item parallax-relative"
            data-depth="1.8"
            :heading="data.sliders.title"
            :textAlign="'right'"
            :timescale="1"
            :customHeadingTimeline="timelines.timelineLeadHeading"
          >
            {{ data.sliders.desc }}
          </lead>

          <box-slider
            :ink="data.images.inkWide"
            :show="showDiagram"
            :data="data.sliders"
          ></box-slider>
        </div>
      </div>
    </div>

    <background-video-full
      :id="'BrokenEuphoricHarborporpoise'"
      :show="showBackground"
      :mask="true"
      :maskOpacity="1"
      :poster="data.images.poster"
      :timescale="1.5"
    ></background-video-full>
    <div class="container absolute ink-container flex">
      <ink
        class
        :customTimeline="timelines.timelineInk"
        :show="showInk"
        :image="data.images.ink"
      ></ink>
    </div>
    <div class="container absolute mix-blend-multiply">
      <decal
        class="absolute"
        :show="showDecal"
        :image="data.images.decal"
        :parallaxDepth="1.5"
        :customTimeline="timelines.timelineHippos"
      ></decal>
    </div>

    <modal-main
      v-for="modal in data.modals"
      :data="modal"
      :key="modal.name"
    ></modal-main>
  </div>
</template>

<script>
import { chapterPart } from "@/mixins/core.js";

import SectionTitle from "@/components/layout/SectionTitle.vue";
import Decal from "@/components/ui/Decal.vue";
import Ink from "@/components/ui/Ink.vue";
import Lead from "@/components/ui/Lead.vue";
import BoxSlider from "@/components/ui/BoxSlider.vue";
import BackgroundVideoFull from "@/components/ui/BackgroundVideoFull.vue";

import ModalMain from "@/modals/ModalMain.vue";

export default {
  name: "Chapter-2-5",
  mixins: [chapterPart],
  components: {
    SectionTitle,
    Decal,
    Ink,
    Lead,
    BoxSlider,
    BackgroundVideoFull,
    ModalMain,
  },
  data() {
    return {
      doParallax: true,
      itemsToShow: [
        ["showDelay", 0],
        ["showBackground", 1600],
        ["showTitle", 400],
        ["showDecal", 50],
        ["showDiagram", 0],
        ["showInk", 100],
        ["showQuote", 0],
        ["showLead", 1000],
      ],
      timelines: {
        timelineHippos: {
          duration: 1.2,
          animations: {
            ease: Power1.easeInOut,
            autoAlpha: 0,
            y: "+=20",
            scale: "0.95",
          },
        },
        timelineInk: {
          duration: 0.7,
          animations: {
            scale: 1,
            x: "+=20%",
            ease: Power2.easeOut,
          },
        },
        timelineLeadHeading: {
          start: 0,
          duration: 0.7,
          animations: {
            autoAlpha: 0,
            ease: Power2.easeInOut,
          },
        },
      },
    };
  },
  computed: {},
};
</script>

<style scoped>
.row-1 {
  padding-bottom: 3rem;
}
.col-1 {
  max-width: 39%;
}
.col-2 {
  margin-top: 2.875em;
  padding-left: 0;
}

.section-decal {
  bottom: 2vh;
  left: 10vw;
  width: 614px;
  max-width: 40vw;
  margin: 0;
}
.portrait .section-decal {
  max-width: 55vw;
}
/deep/ .section-decal img {
  max-height: 30vh;
}
#lead-left {
  margin-bottom: auto;
  margin-top: 2.4%;
}
/deep/ #lead-left .lead {
  max-width: 29em;
}
.ink-container {
  mix-blend-mode: multiply;
  z-index: 1;
  align-items: center;
  justify-content: center;
}
.section-ink {
  opacity: 0.7;
  margin-left: 35%;
  margin-right: -5%;
}
/deep/ .section-ink img {
  width: 1150px;
  max-width: 100%;
  display: block;
}

/deep/ .box-slider {
  max-width: 50em;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (max-width: 1270px) {
  .row-1 {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }
  #lead-left {
    margin-top: 0;
    margin-bottom: 0;
  }
  .col-1 {
    flex-direction: column;
    padding: var(--col-padding);
    justify-content: center;
    align-items: center;
  }
  .section-title {
    max-width: 6.3em;
  }
  #lead-left {
    text-align: left;
  }
  /deep/ #lead-left .lead {
    padding: 0;
    text-align: left;
    max-width: 54em;
  }
  /deep/ #lead-left .lead-line {
    left: -2px;
    right: auto;
  }

  .row-1 > * {
    max-width: 100%;
    width: 100%;
  }
  .col-2 {
    padding: var(--col-padding);
    padding-bottom: 3rem;
    margin-top: 2.4em;
  }
  .section-ink {
    margin-right: -8%;
    margin-left: 0;
    margin-top: 25%;
  }
}

@media screen and (max-width: 991px) {
  /deep/ #lead-left .lead {
    max-width: 38em;
  }
  .section-decal {
    bottom: 3%;
  }
  .section-ink {
    margin-right: -15%;
    margin-top: 30%;
  }
  .ink-container {
    width: 105%;
  }
}

@media screen and (max-width: 767px) {
  .portrait .row-1 {
    padding-bottom: 4rem;
  }
  .ink-container {
    width: 115%;
  }
}

@media screen and (max-width: 639px) {
  #lead-right {
    width: 100%;
    padding: var(--col-padding);
  }
  /deep/ #lead-right .lead {
    display: none;
  }
  /deep/ #lead-right .lead-heading {
    margin-bottom: 0.1em;
  }
  .col-2 {
    padding: 0;
    margin-top: 1.8em;
  }
  .section-ink {
    margin-top: 30%;
  }
  #lead-left {
    margin-right: var(--col-padding-adjust);
  }
}

@media screen and (max-width: 560px) {
  .section-ink {
    margin-top: 45%;
  }
  .ink-container {
    width: 120%;
  }
}

@media screen and (max-width: 479px) {
  /deep/ #lead-right .lead-heading {
    margin-bottom: 0;
  }
  .section-ink {
    margin-top: 50%;
    margin-right: -20%;
  }
  .ink-container {
    width: 125%;
  }
}

@media screen and (max-width: 420px) {
  .section-ink {
    margin-top: 70%;
    margin-right: -20%;
  }
  .ink-container {
    width: 125%;
  }
}
@media screen and (max-width: 390px) {
  .section-ink {
    margin-top: 65%;
    margin-right: -20%;
  }
  .ink-container {
    width: 140%;
  }
}
</style>
